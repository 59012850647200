import React from 'react';
import jsonResumeSchema from 'constants/jsonResumeSchema';
import moment from 'moment';
import SEO from '../../components/SEO';

const Resume = () => {
	const { basics, work } = jsonResumeSchema;

	return (
		<div id="cv">
			<SEO pageTitle="Srikanth Bandaru - CV" pageDescription={basics.summary} />
			<h1 id="name">{basics.name}</h1>
			<p>
				{basics.location.address} · {basics.phone} · <a href={`mailto:${basics.email}`}>{basics.email}</a>{' '}
				<br />
				<a href={basics.website} target="_blank">
					{basics.website.replace(/(^\w+:|^)\/\//, '')}
				</a>{' '}
				·{' '}
				<a href={basics.profiles[1].url} target="_blank">
					{basics.profiles[1].url.replace(/(^\w+:|^)\/\//, '')}
				</a>
			</p>
			<h2 id="title">{basics.label}</h2>
			<p>{basics.summary}</p>
			<h2 id="experience">Experience</h2>
			{work.map(item => (
				<div key={item.shortName}>
					<h3>
						<strong>{item.position}</strong>, {item.company}, {item.location}, (
						{moment(item.startDate, 'MM/DD/YYYY').format('MMM YYYY')}—
						{item.endDate === 'Present' ? 'Present' : moment(item.endDate, 'MM/DD/YYYY').format('MMM YYYY')}
						)
					</h3>
					<h4 className="skills">Skills: {item.technologies.join(', ')}</h4>
					<ul>
						{item.responsibilities.map(responsibility => (
							<li
								dangerouslySetInnerHTML={{ __html: responsibility }}
								key={`responsibility${item.shortName}`}
							/>
						))}
					</ul>
				</div>
			))}
			<h2 id="education">Education</h2>
			<p>
				<strong>Pace University, New York NY, (2015)</strong>
				<br />
				Master of Science in Computer Science - GPA: 3.6/4.0
			</p>
		</div>
	);
};

export default Resume;
